@for $i from 1 through 24 {
  .m-#{$i} {
    margin: #{$i}px;
  }
  .ml-#{$i} {
    margin-left: #{$i}px;
  }
  .mr-#{$i} {
    margin-right: #{$i}px;
  }
  .mt-#{$i} {
    margin-top: #{$i}px;
  }
  .mb-#{$i} {
    margin-bottom: #{$i}px;
  }
}

@for $i from 1 through 24 {
  .p-#{$i} {
    padding: #{$i}px;
  }
  .pl-#{$i} {
    padding-left: #{$i}px;
  }
  .pr-#{$i} {
    padding-right: #{$i}px;
  }
  .pt-#{$i} {
    padding-top: #{$i}px;
  }
  .pb-#{$i} {
    padding-bottom: #{$i}px;
  }
  .pv-#{$i} {
    padding-block: #{$i}px;
  }
  .ph-#{$i} {
    padding-inline: #{$i}px;
  }
}
