@use 'maplibre-gl/dist/maplibre-gl.css';

// Rimozione info-contributor
.mapboxgl-ctrl-attrib.mapboxgl-compact-show,
.maplibregl-ctrl-attrib.maplibregl-compact-show {
  padding: 2px 28px 2px 8px;
  visibility: hidden !important;
}
.mapboxgl-ctrl-attrib.mapboxgl-compact .mapboxgl-ctrl-attrib-button,
.mapboxgl-ctrl-attrib.mapboxgl-compact-show .mapboxgl-ctrl-attrib-inner,
.maplibregl-ctrl-attrib.maplibregl-compact .maplibregl-ctrl-attrib-button,
.maplibregl-ctrl-attrib.maplibregl-compact-show .maplibregl-ctrl-attrib-inner {
  display: none;
}
.mapboxgl-ctrl-attrib.mapboxgl-compact,
.maplibregl-ctrl-attrib.maplibregl-compact {
  background-color: #fff;
  border-radius: 12px;
  margin: 10px;
  min-height: 20px;
  padding: 2px 24px 2px 0;
  position: relative;
  display: none;
}
.mapboxgl-ctrl-bottom-right, .maplibregl-ctrl-bottom-right {
  bottom: 50px;
  .maplibregl-ctrl{
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    -o-border-radius: 16px;
}
  button{
    height: 28px;
    width: 28px;
}
}
// btn centra mappa

// .mapboxgl-ctrl button.mapboxgl-ctrl-geolocate .mapboxgl-ctrl-icon,
// .maplibregl-ctrl button.maplibregl-ctrl-geolocate .maplibregl-ctrl-icon {
//   background-color: #297eb2;
//   color: #fff;
//   border-radius: 5px;
// }
