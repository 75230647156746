ion-card {
  margin: 0;
  margin: 10px;
  border-radius: var(--yf-border-radius);
  background-color: transparent;
}

.debug-lite {
  border: 1px solid yellow;
}
