@use '../abstracts/variables.scss';

.yf-grid-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  padding: 12px;
  gap: 12px;
}

.yf-grid-item {
  box-sizing: border-box;
  display: block;
  width: -webkit-fill-available;
  max-width: calc((100% - 12px) / 2);
  max-height: calc((100% - 12px) / 2);
}

.yf-dflex{
  display: flex;
}

.yf-container {
  display: flex;
  flex-direction: column;
  &.stretched {
    align-items: stretch;
  }
  &.centered {
    align-items: center;
    justify-content: center;
  }
  &.align-left {
    align-items: flex-start;
  }
  &.align-right {
    align-items: flex-end;
  }
  &.spaced {
    justify-content: space-between;
  }
  &.j-center{
    justify-content: center;
  }
}

.yf-row {
  display: flex;
  flex-direction: row;
  &.centered {
    justify-content: center;
    align-items: center;
  }
  &.spaced {
    justify-content: space-between;
    // align-items: center;
  }
  &.align-right{
    justify-content: end;
  }
  &.align-left {
    justify-content: flex-start;
    align-items: center;
  }
  &.top {
    align-items: flex-start;
  }
  &.bottom {
    align-items: flex-end;
  }
  &.center {
    align-items: center;
  }
  &.wrap {
    flex-wrap: wrap;
  }
}

@for $i from 1 through 11 {
  .gap-#{$i} {
    gap: #{$i}px;
  }
}

@for $i from 1 through 12 {
  .m-#{$i} {
    margin: #{$i}px;
  }
}

.gap-12 {
  gap: var(--yf-spacing1);
}

.gap-24 {
  gap: var(--yf-spacing2);
}

.gap-36 {
  gap: var(--yf-spacing3);
}

.grow {
  flex-grow: 1;
}

.wrap {
  flex-wrap: wrap;
}

.balance {
  flex: 1;
}
