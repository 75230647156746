@use '../abstracts/variables.scss';

.yf-alert {
  --max-width: 300px;
  --background: var(--ion-color-light);
  --ion-backdrop-color: #0d2c40;
  --backdrop-opacity: 0.6;
  div:empty {
    display: none;
  }
  .alert-wrapper {
    border-radius: var(--yf-border-radius);
    .alert-head {
      padding: var(--yf-spacing2) var(--yf-spacing2) var(--yf-spacing3) var(--yf-spacing2);
      .alert-title {
        color: var(--ion-color-primary);
        font-size: 20px;
        text-align: center;
        font-weight: 600;
      }
    }
    .alert-message {
      padding: var(--yf-spacing3);
      color: var(--ion-color-secondary);
      font-size: 14px;
      text-align: center;
      max-height: 450px;
      display: flex;
      flex-direction: column;
      gap: var(--yf-spacing3);
      img {
        max-height: 150px;
      }
    }
    .alert-button-group {
      padding: 0px var(--yf-spacing2) var(--yf-spacing2) var(--yf-spacing2);
      flex-direction: column;
      gap: 6px;
      .alert-button {
        background-color: var(--ion-color-secondary);
        color: white;
        box-shadow: var(--yf-shadow);
        border-radius: var(--yf-border-radius);
        text-transform: unset;
        margin-inline-end: 0px;
        font-size: 14px;
        font-weight: 600;
        span {
          justify-content: center;
        }
      }
      .alert-button:nth-child(2) {
        background-color: var(--ion-color-primary);
        // background-color: white;
        // color: var(--ion-color-secondary);
        // border: 2px solid var(--ion-color-secondary);
      }
    }
  }
}

.yf-alert-landscape {
  --max-width: 380px;
  --max-height: 260px;
  --background: var(--ion-color-light);
  --ion-backdrop-color: #0d2c40;
  --backdrop-opacity: 0.6;
  div:empty {
     display: none;
  }
  .yf-flex {
    display: flex; 
    flex-direction: column;
  }
  ion-icon {
    font-size: 24px;
  }
  .desc {
    text-align: left;
  }
}

// .yf-alert-landscape {
//   --max-width: 360px;
//   --max-height: 212px;
//   --background: var(--ion-color-light);
//   --ion-backdrop-color: #0d2c40;
//   --backdrop-opacity: 0.6;
//   div:empty {
//     display: none;
//   }
//   .alert-wrapper {
//     border-radius: var(--yf-border-radius);
//     .alert-head {
//       padding: var(--yf-spacing2) var(--yf-spacing2) var(--yf-spacing3) var(--yf-spacing2);
//       .alert-title {
//         color: var(--ion-color-primary);
//         font-size: 20px;
//         text-align: center;
//         font-weight: 600;
//       }
//     }
//     .alert-message {
//       padding: var(--yf-spacing3);
//       color: var(--ion-color-secondary);
//       font-size: 14px;
//       text-align: center;
//       max-height: 450px;
//       display: flex;
//       flex-direction: column;
//       gap: var(--yf-spacing3);
//       img {
//         max-height: 150px;
//       }
//     }
//     .alert-button-group {
//       padding: 0px var(--yf-spacing2) var(--yf-spacing2) var(--yf-spacing2);
//       flex-direction: column;
//       gap: 6px;
//       .alert-button {
//         background-color: var(--ion-color-secondary);
//         color: white;
//         box-shadow: var(--yf-shadow);
//         border-radius: var(--yf-border-radius);
//         text-transform: unset;
//         margin-inline-end: 0px;
//         font-size: 14px;
//         font-weight: 600;
//         span {
//           justify-content: center;
//         }
//       }
//       .alert-button:nth-child(2) {
//         background-color: var(--ion-color-primary);
//         // background-color: white;
//         // color: var(--ion-color-secondary);
//         // border: 2px solid var(--ion-color-secondary);
//       }
//     }
//   }
// }



.card-auth-failed-alert .alert-wrapper .alert-head .alert-title {
  color: red;
}
