.display-flex-column {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.display-flex-row-master {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;
}
.display-flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.flex-3 {
  flex: 3;
}
.flex-4 {
  flex: 4;
}
.flex-5 {
  flex: 5;
}
.flex-6 {
  flex: 6;
}
/* [class*='flex-'] {
  padding-left: 10px;
  padding-right: 10px;
}
[class*='flex-']:first-child {
  padding-left: 0;
}
[class*='flex-']:nth-last-child(2) {
  padding-right: 0;
} */
