// @use url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

body {
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
  //   'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@for $i from 2 through 96 {
  .fs-#{$i} {
      font-size: #{$i}px;
  }
}

@for $i from 100 through 900 {
  .fw-#{$i} {
      font-weight: #{$i};
  }
}

.icon-aligned {
  vertical-align: text-top;
}
