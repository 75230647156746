.yf-state {

  &.available {
    background-color: var(--ion-color-primary);

    ion-text {
      color: var(--ion-color-primary-contrast);
    }
  }

  &.reserved {
    background-color: var(--ion-color-tertiary);

    ion-text {
      color: var(--ion-color-tertiary-contrast);
    }
  }

  &.charging {
    background-color: var(--ion-color-warning);

    ion-text {
      color: var(--ion-color-warning-contrast);
    }
  }

  &.parking {
    background-color: var(--ion-color-danger);

    ion-text {
      color: var(--ion-color-danger-contrast);
    }
  }

  &.unavailable {
    background-color: var(--ion-color-medium);

    ion-text {
      color: var(--ion-color-medium-contrast);
    }
  }
}