@use "sass:map";
$breakpoints: (
  xxs: 375px,
  xs: 450px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

// mobile-first breakpoint mixin
@mixin breakpoint($breakpoint: md, $key: min, $orientation: false) {
  @if ($orientation) {
    @media (#{$key}-width: map.get($breakpoints, $breakpoint)) and (orientation : $orientation) {
      @content;
    }
  } @else {
    @media (#{$key}-width: map.get($breakpoints, $breakpoint)) {
      @content;
    }
  }
}
