@use '../abstracts/variables.scss';
@use '../abstracts/mixins.scss';

.yf-master-detail-modal {

    --background: var(--ion-color-light);
    ion-content {
      --background: transparent;
    }
    // @include breakpoint(lg, max) {
    //   --max-height: unset;
    // }

    @include mixins.breakpoint(sm, min) {
      --height: 100%;
      --width: 100%;
      --border-radius: 0;
      border: 0px;
    }

    @include mixins.breakpoint(md, min, portrait) {
      --ion-backdrop-color: #0d2c40;
      --backdrop-opacity: 0.6;
      --border-radius: #{var(--yf-border-radius)};
      --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
      --height: 80%;
      --width: 90%;

    }

    @include mixins.breakpoint(md, min, landscape) {
      --ion-backdrop-color: #0d2c40;
      --backdrop-opacity: 0.6;
      --border-radius: #{var(--yf-border-radius)};
      --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
      --height: 95%;
      --width: 85%;
    }

    @include mixins.breakpoint(lg, min, portrait) {
      --ion-backdrop-color: #0d2c40;
      --backdrop-opacity: 0.6;
      --border-radius: #{var(--yf-border-radius)};
      --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
      --height: 70%;
      --width: 90%;
    }

    @include mixins.breakpoint(lg, min, landscape) {
      --ion-backdrop-color: #0d2c40;
      --backdrop-opacity: 0.6;
      --border-radius: #{var(--yf-border-radius)};
      --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
      --height: 80%;
      --width: 80%;
    }

  }

  .yf-master-detail {
    height: 100%;
  }

  .yf-master-detail-modal-always-full {

    --background: var(--ion-color-light);
    ion-content {
      --background: transparent;
    }

    @include mixins.breakpoint(sm, max) {
      --height: 100%;
      --width: 100%;
      --border-radius: 0;
      border: 0px;
    }

    @include mixins.breakpoint(md, min) {
      --height: 80%;
      // --width: 100%;
      --border-radius: 12px;
      border: 0px;
    }

  }


.master-content {
  height: 100%;
}
