b {
  font-weight: 600;
}

h1 {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 0px;
  margin-top: 0;
}

h2 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0px;
  margin-top: 0px;
}

h4 {
  font-weight: 600;
  font-size: 16px;
  margin-top: 0px;
  margin-bottom: 0px;
}

h5 {
  font-weight: 400;
  margin-top: 0px;
  margin-bottom: 0px;
}

h6 {
  font-size: var(--yf-spacing1);
  margin: 0;
  font-weight: 600;
}

p {
  font-size:14px;// var(--yf-spacing1);
  margin: 0;
  font-weight: 400;
}

hr {
  height: 2px;
  border-width: 0;
  margin: 0px;
  background-color: #edeef3;
}

.yf-text-center {
  text-align: center;
}
