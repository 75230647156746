@use '../abstracts/variables.scss';
@use '../abstracts/mixins.scss';

.login-modal {
  --height: auto;
  --border-radius: #{var(--yf-border-radius)};
  --background: var(--ion-color-light);
  &::part(backdrop) {
    --background: transparent;
    --ion-backdrop-color: #0b2738;
    // backdrop-filter: blur(2px);
  }
  &::part(content) {
    height: auto;
    @include mixins.breakpoint(xs, max) {
      width: 90vw;
    }
    @include mixins.breakpoint(xs, min) {
      width: 350px;
      align-self: center;
    }
  }
  .ion-page {
    position: relative;
    display: block;
    contain: content;
    .inner-content {
      max-height: 80vh;
      padding: 24px;
      .form-container {
        max-height: calc(80vh - 48px - 80px); // - padding - header
        overflow: auto;
      }
    }
  }
}

.yf-modal {
  --background: var(--ion-color-light);
  ion-content {
    --background: transparent;
  }
  @include mixins.breakpoint(sm, max) {
    --max-height: unset;
  }
  @include mixins.breakpoint(sm, min) {
    --ion-backdrop-color: #0d2c40;
    --backdrop-opacity: 0.6;
    --border-radius: #{var(--yf-border-radius)};
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
    --height: 80vh;
    --width: 500px;
  }
}



// .station-modal {
//   --background: transparent;
//   --ion-backdrop-color: transparent;
//   --box-shadow: none;
//   --height: auto;
//   --width: auto;
//   &::part(content) {
//     position: absolute;
//     bottom: var(--yf-spacing2);
//     @include breakpoint(sm, max) {
//       left: var(--yf-spacing2);
//       right: var(--yf-spacing2);
//       display: flex;
//       justify-content: center;
//     }
//     @include breakpoint(sm, min) {
//       left: calc(80px); // 72px + 8px calcolati a mano
//     }
//   }
//   .ion-page {
//     position: relative;
//     display: block;
//     contain: content;
//     .inner-content {
//       max-height: 80vh;
//       background: transparent;
//       ion-card {
//         max-height: calc(80vh - 48px - 62px); // - padding - header
//         max-width: 397px; // 400px - 3px calcolati a mano
//         box-sizing: content-box;
//         overflow: auto;
//       }
//     }
//   }
// }

// /* md-up */
// @media only screen and (min-width: 768px) {
//   .custom-modal::part(content) {
//     max-width: 500px;
//     min-height: 700px;
//   }
// }

// /* md-down */
// @media only screen and (max-width: 768px) {
//   .custom-modal::part(content) {
//     max-width: unset;
//   }
// }
