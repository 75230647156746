.detailText {
  font-size: 14px;
  font-weight: 500;
}

.detailTextStation {
  font-size: 14px;
  font-weight: 400;
}

.detailUmText {
  font-size: 11px;
}

.costText {
  font-size: 24px;
  font-weight: 600;
}