@use "../abstracts/variables.scss";

// TODO : rename to yf-button
.btn {
  --box-shadow: #{var(--yf-shadow)};
  --border-radius: #{var(--yf-border-radius)};  
  --padding-start: #{var(--yf-spacing1)};
  --padding-end: #{var(--yf-spacing1)};
  --padding-bottom: #{var(--yf-spacing1)};
  --padding-top: #{var(--yf-spacing1)};
  margin: 0;
  .divider {
    border-top: 2px solid #edeef3;
  }
  &.transparent {
    --background: none;
    --background-hover: var(--ion-color-secondary);
    --background-focused: var(--ion-color-secondary);
    --color: var(--ion-color-secondary);
    --box-shadow: transparent;
  }
  &.white {
    --background: #fff;
    --background-hover: var(--ion-color-secondary);
    --background-focused: var(--ion-color-secondary);
    --color: var(--ion-color-secondary);
    --color-hover: #fff;
    .divider {
      border-top: 2px solid #edeef3;
    }
  }
  &.primary {
    --background: var(--ion-color-primary);
    --background-hover: #fff;
    --background-focused: #fff;
    --color: #fff;
    --color-hover: var(--yf-primary-dark); //Funziona
    .divider {
      border-top: 2px solid #ffffff80;
    }
  }
  &.primary-dark {
    --background: var(--yf-primary-dark);
    --background-hover: #fff;
    --background-focused: #fff;
    --color: #fff;
    --color-hover: var(--yf-white);
    .divider {
      border-top: 2px solid #ffffff80;
    }
  }
  &.secondary {
    --background: var(--ion-color-secondary);
    --background-hover: #fff;
    --background-focused: #fff;
    --color: #fff;
    --color-hover: var(--ion-color-secondary);
    .divider {
      border-top: 2px solid #ffffff80;
    }
  }
  &.accent {
    --background: var(--ion-color-tertiary);
    --color: var(--ion-color-secondary);
    .divider {
      border-top: 2px solid var(--ion-color-tertiary-shade);
    }
  }
  &.reserved {
    --background: var(--ion-color-tertiary);
    --color: var(--ion-color-secondary);
    .divider {
      border-top: 2px solid var(--ion-color-secondary);
    }
  }
  &.charging {
    --background: var(--ion-color-warning);
    --color: var(--ion-color-secondary);
    .divider {
      border-top: 2px solid var(--ion-color-secondary);
    }
  }
  &.parking {
    --background: var(--ion-color-danger);
    --color: var(--ion-color-primary-contrast);
    .divider {
      border-top: 2px solid var(--ion-color-primary-contrast);
    }
  }
  &.disabled {
    --background: #d6d9e1;
    --background-hover: #404244;
    --background-focused: #404244;
    --color: #404244;
    .divider {
      border-top: 2px solid #40424480;
    }
  }
  &.square {
    height: var(--yf-spacing4);
    width: var(--yf-spacing4);
    ion-icon {
      font-size: 30px;
    }
  }
  &.square-big {
    height: var(--yf-spacing5);
    width: var(--yf-spacing5);
    ion-icon {
      font-size: 36px;
    }
  }
  @for $i from 6 through 96 {
    &.square-#{$i} {
      height: #{$i}px;
      width: #{$i}px;
      ion-icon {
        font-size: calc((#{$i}px / 2) - 8px);
      }
    }
  }
  &.small {
    height: var(--yf-spacing2);
    width: auto;
    font-size: var(--yf-spacing1);
    font-weight: 600;
    ion-icon {
      margin-top: -2px;
      font-size: var(--yf-spacing1);
    }
  }
  &.medium {
    height: var(--yf-spacing3);
    width: auto;
    font-size: var(--yf-spacing1);
    --padding-start: #{var(--yf-spacing2)};
    --padding-end: #{var(--yf-spacing2)};
    ion-icon {
      margin-right: var(--yf-spacing1);
      font-size: 20px;
    }
  }
  &.large {
    height: var(--yf-spacing4);
    width: 100%;
    font-size: 16px;
    --padding-start: #{var(--yf-spacing2)};
    --padding-end: #{var(--yf-spacing2)};
    ion-icon {
      font-size: 30px;
    }
  }
}

.only-icon-button {
  height: var(--yf-spacing2);
  width: var(--yf-spacing2);
  --border-radius: 50px;
  --box-shadow: transparent;
  --padding-start: 0;
  --padding-end: 0;
  --padding-bottom: 0;
  --padding-top: 0;
  margin: 0;
  ion-icon {
    font-size: var(--yf-spacing1);
  }
}

.chip-button {
  height: 32px;
  width: auto;
  font-size: 8px;
  --border-radius: 50px;
  --box-shadow: #{var(--yf-shadow)};
  --padding-start: 16px;
  --padding-end: 16px;
  --padding-bottom: 0;
  --padding-top: 0;
  margin: 0;
}

button.alert-button.alert-button-role-confirm.alert-button-danger {
  background-color: var(--ion-color-danger);
  color: var(--ion-color-primary-contrast);
}

button.alert-button.alert-button-role-confirm.alert-button-primary {
  background-color: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
}

button.alert-button.alert-button-role-confirm.alert-button-secondary {
  background-color: var(--ion-color-secondary);
  color: var(--ion-color-primary-contrast);
}

button.alert-button.alert-button-role-confirm.alert-button-tertiary {
  background-color: var(--ion-color-tertiary);
  color: var(--ion-color-primary-contrast);
}
