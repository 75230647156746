@use '../abstracts/variables.scss';

ion-modal {
  min-zoom: 0.9;
  zoom: 0.9;
  max-zoom: 0.9;
}

ion-fab {
  // min-zoom: 0.9;
  // zoom: 0.9;
  // max-zoom: 0.9;
}

.yf-content-padding {
  --padding-top: #{var(--yf-spacing1)};
  --padding-start: #{var(--yf-spacing2)};
  --padding-end: #{var(--yf-spacing2)};
  --padding-bottom: #{var(--yf-spacing1)};
}

.yf-padding {
  padding-top: var(--yf-spacing1);
  padding-bottom: var(--yf-spacing1);
  padding-left: var(--yf-spacing1);
  padding-right: var(--yf-spacing1);
}

.yf-padding-inline {
  padding-left: var(--yf-spacing1);
  padding-right: var(--yf-spacing1);
}

.yf-padding-block {
  padding-top: var(--yf-spacing1);
  padding-bottom: var(--yf-spacing1);
}

.yf-margin {
  margin: var(--yf-spacing1);
}

.yf-header-padding {
  padding: var(--yf-spacing2) var(--yf-spacing2) var(--yf-spacing1) var(--yf-spacing2);
}

.yf-footer-padding {
  padding: var(--yf-spacing1) var(--yf-spacing2) var(--yf-spacing2) var(--yf-spacing2);
}

ion-button {
  text-transform: none;
  font-weight: 600;
  // margin: 0;
}

ion-list {
  background: transparent !important;
}

ion-item {
  --background: transparent !important;
  // --min-height: unset;
  &::part(native) {
    padding-inline-start: 0px;
    min-height: 48px;
    // align-items: center;
  }
}

.item-label-floating {
  font-size: 14px !important;
}

ion-input {
  --padding-top: 0 !important;
  --padding-bottom: 0 !important;
  align-items: flex-end !important;
  --color: var(--ion-color-dark) !important;
  font-weight: 600 !important;
}

ion-select {
  // max-width: 65%;
  --padding-top: 0;
  --padding-bottom: 0;
  align-items: flex-end;
  // height: 31.96px;
  &::part(icon) {
    transform: unset;
  }
  &::part(text) {
    color: var(--ion-color-dark);
    font-weight: 600;
  }
}

ion-card {
  box-shadow: unset;
}

.header-md::after {
  display: none;
}

// SCROLLBAR HIDE

*::-webkit-scrollbar {
  width: 10px !important;
  height: 10px !important;
  background-color: white !important;
}

*::-webkit-scrollbar-thumb {
  width: 10px !important;
  height: 10px !important;
  background-color: whitesmoke !important;
  border-radius: 12px;
}

*::-webkit-scrollbar:horizontal {
  width: 10px !important;
  height: 10px !important;
  background-color: white !important;
}

*::-webkit-scrollbar-thumb:horizontal {
  width: 10px !important;
  height: 10px !important;
  background-color: whitesmoke !important;
  border-radius: 12px;
}
