ion-toolbar {
    --min-height:48px !important;
    div.searchbar-input-container {
      &.sc-ion-searchbar-ios {
        height: 48px;
      }
      &.sc-ion-searchbar-md {
        height: 100%;
        & input {
          border-radius: var(--yf-border-radius);
        }
      }
    }
}